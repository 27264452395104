import styled from 'styled-components';

export const Container = styled.div`
  width: auto;
  height: auto;
  max-height: 80px;
  text-align: center;
  margin: 10px auto 10px;
  color: #fff;
  font-weight: bold;
  font-size: 20px;
  padding: 10px 30px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 5px;
  user-select: none;
`;

export const Image = styled.img`
  user-select: none;
  width: auto;
  height: auto;
  max-width: 300px;
  max-height: 80px;
  margin: 10px auto 10px;
`;
