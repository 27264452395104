import axios from 'axios';

import { API_URL } from '../constants';
import auth from './auth';

const baseURL = API_URL;

const api = axios.create({
  baseURL,
});

api.interceptors.request.use(
  config => {
    // Do something before request is sent
    let myConfig = {};
    if (auth.isAuthenticated()) {
      myConfig = {
        headers: {
          Authorization: `Bearer ${auth.getUser().token}`,
        },
      };
    }

    return { ...config, ...myConfig };
  },
  error => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  response => {
    // Do something with response data
    if (response.status === 401) {
      auth.removeUser();
      window.location = '/logout';
    }
    return response;
  },
  error => {
    // Do something with response error
    if (error && error.response && error.response.status === 401) {
      auth.removeUser();
      window.location = '/logout';
    }
    return Promise.reject(error);
  }
);

export default api;
