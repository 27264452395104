import styled from 'styled-components';

export const Container = styled.div`
  align-self: center;
  margin-bottom: 15px;
  .alert {
    color: #000;
    background: #ccc;
    text-align: center;
  }
  .label-preview {
    color: #1169d9;
    text-align: right;
  }

  label {
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
    img {
      padding: 5px;
      background: #374667;
      max-width: 100%;
      max-height: 100%;
    }
    input {
      display: none;
    }
  }
`;
