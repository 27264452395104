import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import Dashboard from './pages/Dashboard';
import DashboardMock from './pages/DashboardMock';
import Companies from './pages/Companies';
import Login from './pages/Login';
import auth from './services/auth';
import Users from './pages/Users';
import Boards from './pages/Boards';

function PrivateRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={props =>
        auth.isAuthenticated() ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
}

const Logout = () => {
  auth.removeUser();
  return (
    <Redirect
      to={{
        pathname: '/login',
        state: { isLogout: true },
      }}
    />
  );
};

export default function Routes() {
  return (
    <Switch>
      <Route path="/login" component={Login} />
      <PrivateRoute path="/" exact component={Dashboard} />
      <PrivateRoute path="/empresas" exact component={Companies} />
      <PrivateRoute path="/empresas/:id/placas" component={Boards} />
      <PrivateRoute path="/empresas/1/dashboard" component={DashboardMock} />
      <PrivateRoute path="/empresas/:id/dashboard" component={Dashboard} />
      <PrivateRoute path="/usuarios" exact component={Users} />
      <Route path="/logout" component={Logout} />
    </Switch>
  );
}
