import styled from 'styled-components';

export const SubmitButton = styled.button.attrs(props => ({
  type: 'submit',
  disabled: props.isSubmitting,
  className: 'btn btn-primary',
}))`
  display: flex;
  align-items: center;

  svg {
    margin-right: 10px;
  }
`;
