import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import api from '../../services/api';
import Navbar from '../../components/Navbar';
import { CompaniesContainer, CompaniesList } from './styles';
import ModalAddCompany from '../../components/ModalAddCompany';
import Loading from '../../components/Loading';
import LabelStatus from '../../components/LabelStatus';

export default function Companies() {
  const [showModalCompany, setShowModalCompany] = useState(false);
  const [companyToEdit, setCompanyToEdit] = useState(null);
  const [companies, setCompanies] = useState([]);
  const [loadingCompanies, setLoadingCompanies] = useState(false);

  useEffect(() => {
    async function getCompanies() {
      setLoadingCompanies(true);
      const { data } = await api.get('/companies');
      setLoadingCompanies(false);
      setCompanies(data);
    }

    getCompanies();
  }, []);

  useEffect(() => {
    if (!showModalCompany) {
      setTimeout(() => {
        setCompanyToEdit(null);
      }, 500);
    }
  }, [showModalCompany]);

  const handleShowModalCompany = () => setShowModalCompany(true);

  const handleToEditCompany = company => {
    setCompanyToEdit(company);
    setShowModalCompany(true);
  };

  const updateCompanies = newCompany => {
    const existsCompany = companies.find(item => item.id === newCompany.id);
    let updatedCompanies = companies;
    if (existsCompany) {
      toast.success('Cliente atualizado com sucesso');
      updatedCompanies = updatedCompanies.map(item => {
        if (item.id !== newCompany.id) return item;

        return {
          ...item,
          ...newCompany,
        };
      });
    } else {
      toast.success('Cliente adicionado com sucesso');
      updatedCompanies.push(newCompany);
    }

    setCompanies(updatedCompanies);
    setShowModalCompany(false);
  };

  return (
    <CompaniesContainer>
      <Navbar />

      {loadingCompanies && <Loading label="Carregando empresas..." />}

      {!loadingCompanies && (
        <div className="container">
          <div className="row">
            <header className="col-12 d-flex justify-space-between">
              <h2 className="mr-auto">Clientes</h2>
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleShowModalCompany}
              >
                Adicionar
              </button>
            </header>
          </div>
          <div className="row">
            <div className="col-12 col-md-8 offset-md-2">
              <CompaniesList>
                {companies.map(company => (
                  <li key={company.id}>
                    <button
                      type="button"
                      onClick={() => handleToEditCompany(company)}
                    >
                      <div className="Description">
                        <div className="Id">{company.id}</div>
                        <strong className="Name">{company.name}</strong>
                        <LabelStatus active={company.active} />
                      </div>
                      <div className="Actions">
                        <Link
                          className="btn btn-outline-light btn-sm"
                          to={`/empresas/${company.id}/placas`}
                        >
                          Ver Placas
                        </Link>
                        <Link
                          className="btn btn-outline-light btn-sm"
                          to={`/empresas/${company.id}/dashboard`}
                        >
                          Ver Dashboard
                        </Link>
                      </div>
                    </button>
                  </li>
                ))}
              </CompaniesList>
            </div>
          </div>
        </div>
      )}
      <ModalAddCompany
        show={showModalCompany}
        company={companyToEdit}
        onHandleClose={() => setShowModalCompany(false)}
        onSaveCompany={updateCompanies}
      />
    </CompaniesContainer>
  );
}
