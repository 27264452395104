import styled from 'styled-components';

export const LoginContainer = styled.div`
  margin-top: 30px;
  &,
  & > .container,
  & > .container > .row {
    min-height: 100vh;
  }
`;

export const ErrorMessage = styled.div`
  color: red;
`;
