import React from 'react';

import { Container } from './styles';

function LabelStatus({ active }) {
  return (
    <Container active={Number(active) === 1}>
      {Number(active) === 1 ? 'ativo' : 'inativo'}
    </Container>
  );
}

export default LabelStatus;
