import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { FaCircleNotch } from 'react-icons/fa';
import MaskedInput from 'react-text-mask';
import PropTypes from 'prop-types';

import { SubmitButton } from '../../styles/button';
import api from '../../services/api';
import { MASK_CNPJ, maskCellPhone } from '../../utils/mask';
import LogoInput from './LogoInput';

export default function ModalAddCompany({
  show,
  onHandleClose,
  company = null,
  onSaveCompany,
}) {
  const [errorSavingCompany, setErrorSavingCompany] = useState(null);

  const CompanySchema = Yup.object().shape({
    name: Yup.string().required('Informe o nome da empresa'),
    corporate_name: Yup.string(),
    cnpj: Yup.string(),
    email: Yup.string(),
    cell_phone: Yup.string(),
  });

  const saveCompany = async (request, actions) => {
    setErrorSavingCompany(null);
    try {
      let response = {};
      if (request.id) {
        response = await api.put(`/companies/${request.id}`, request);
      } else {
        response = await api.post('/companies', request);
      }
      const { data } = response;
      onSaveCompany(data);
      actions.setSubmitting(false);
    } catch (e) {
      console.error(e);
      actions.setSubmitting(false);
      setErrorSavingCompany('Erro ao criar empresa');
    }
  };

  const initialValues = company || { name: '', active: true };

  return (
    <>
      <Modal show={show} onHide={onHandleClose} backdrop="static">
        <Modal.Header closeButton>
          {company ? (
            <Modal.Title>Editando Empresa</Modal.Title>
          ) : (
            <Modal.Title>Adicionando Empresa</Modal.Title>
          )}
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={initialValues}
            onSubmit={saveCompany}
            validationSchema={CompanySchema}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              errors,
              isSubmitting,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                <LogoInput
                  defaultValue={values.logo}
                  onSaveFile={logo => {
                    setFieldValue('file_id', logo.id);
                    setFieldValue('logo', logo);
                  }}
                />
                {company && company.id && (
                  <div className="form-group form-check">
                    <input
                      type="checkbox"
                      id="active"
                      onChange={handleChange}
                      checked={values.active}
                      name="active"
                      className={`form-check-input ${errors.active &&
                        'is-invalid'}`}
                      disabled={isSubmitting}
                    />
                    <label className="form-check-label" htmlFor="active">
                      Empresa Ativa
                    </label>
                    {errors.active && (
                      <div className="invalid-feedback">{errors.active}</div>
                    )}
                  </div>
                )}
                <div className="form-group">
                  <label htmlFor="name">Nome da empresa*</label>
                  <input
                    type="text"
                    id="name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                    name="name"
                    className={`form-control ${errors.name && 'is-invalid'}`}
                    placeholder="Nome da empresa"
                    disabled={isSubmitting}
                  />
                  {errors.name && (
                    <div className="invalid-feedback">{errors.name}</div>
                  )}
                </div>
                <div className="form-group">
                  <label htmlFor="corporate_name">Razão social</label>
                  <input
                    type="text"
                    id="corporate_name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.corporate_name}
                    name="corporate_name"
                    className={`form-control ${errors.corporate_name &&
                      'is-invalid'}`}
                    placeholder="Nome da empresa"
                    disabled={isSubmitting}
                  />
                  {errors.corporate_name && (
                    <div className="invalid-feedback">
                      {errors.corporate_name}
                    </div>
                  )}
                </div>
                <div className="form-group">
                  <label htmlFor="cnpj">CNPJ</label>
                  <MaskedInput
                    mask={MASK_CNPJ}
                    type="tel"
                    id="cnpj"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.cnpj}
                    name="cnpj"
                    className={`form-control ${errors.cnpj && 'is-invalid'}`}
                    placeholder="00.000.000/0000-00"
                    disabled={isSubmitting}
                  />
                  {errors.cnpj && (
                    <div className="invalid-feedback">{errors.cnpj}</div>
                  )}
                </div>
                <div className="form-group">
                  <label htmlFor="email">Email</label>
                  <input
                    type="text"
                    id="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    name="email"
                    className={`form-control ${errors.email && 'is-invalid'}`}
                    placeholder="email@empresa.com.br"
                    disabled={isSubmitting}
                  />
                  {errors.email && (
                    <div className="invalid-feedback">{errors.email}</div>
                  )}
                </div>
                <div className="form-group">
                  <label htmlFor="cell_phone">Telefone*</label>
                  <MaskedInput
                    mask={maskCellPhone}
                    type="tel"
                    id="cell_phone"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.cell_phone}
                    name="cell_phone"
                    className={`form-control ${errors.cell_phone &&
                      'is-invalid'}`}
                    placeholder="(00) 0000-0000"
                    disabled={isSubmitting}
                  />
                  {errors.cell_phone && (
                    <div className="invalid-feedback">{errors.cell_phone}</div>
                  )}
                </div>

                {errorSavingCompany && (
                  <div className="text-danger mb-2">{errorSavingCompany}</div>
                )}

                <SubmitButton isSubmitting={isSubmitting}>
                  {isSubmitting ? (
                    <>
                      <FaCircleNotch size={15} className="spin" /> Salvando...
                    </>
                  ) : (
                    'Salvar'
                  )}
                </SubmitButton>
              </form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
}

ModalAddCompany.propTypes = {
  show: PropTypes.bool.isRequired,
  onHandleClose: PropTypes.func.isRequired,
  company: PropTypes.shape({
    name: PropTypes.string,
    cnpj: PropTypes.string,
    email: PropTypes.string,
    cell_phone: PropTypes.string,
  }),
  onSaveCompany: PropTypes.func.isRequired,
};

ModalAddCompany.defaultProps = {
  company: null,
};
