import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import api from '../../../services/api';
import { Container } from './styles';
import { API_URL } from '../../../constants';

export default function LogoInput({ onSaveFile, defaultValue = {} }) {
  const [preview, setPreview] = useState(defaultValue && defaultValue.url);
  const [isUploading, setIsUploading] = useState(false);

  const ref = useRef();

  async function handleChange(e) {
    const data = new FormData();

    data.append('file', e.target.files[0]);

    try {
      setIsUploading(true);
      const response = await api.post('/files', data);
      setIsUploading(false);

      const file = response.data;
      onSaveFile(file);
      setPreview(file.url);
      toast.success('Upload concluído com sucesso');
    } catch (error) {
      toast.error('Erro ao fazer upload');
      console.error(error);
    }
  }

  return (
    <Container>
      {isUploading && 'Enviando imagem...'}
      {!isUploading && (
        <label htmlFor="avatar">
          <div className="wrapper-preview">
            {preview ? (
              <>
                <div className="label-preview">Editar</div>
                <img src={`${API_URL}${preview}`} alt="Preview" />
              </>
            ) : (
              <div className="alert">Adicionar imagem</div>
            )}
          </div>
          <input
            type="file"
            name="avatar"
            id="avatar"
            accept="image/*"
            onChange={handleChange}
            ref={ref}
          />
        </label>
      )}
    </Container>
  );
}

LogoInput.propTypes = {
  onSaveFile: PropTypes.func.isRequired,
};
