import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

import api from '../../services/api';
import Navbar from '../../components/Navbar';
import { Container, BoardsList } from './styles';
import ModalAddBoard from '../../components/ModalAddBoard';
import Loading from '../../components/Loading';
import LabelStatus from '../../components/LabelStatus';

export default function Boards({ match }) {
  const [showModalBoard, setShowModalBoard] = useState(false);
  const [boardToEdit, setBoardToEdit] = useState({
    company_id: match.params.id,
  });
  const [boards, setBoards] = useState([]);
  const [company, setCompany] = useState(null);
  const [loadingBoards, setloadingBoards] = useState(false);

  useEffect(() => {
    async function getBoards() {
      setloadingBoards(true);
      const { data } = await api.get(`/companies/${match.params.id}`);
      setloadingBoards(false);
      setBoards(data.boards);
      setCompany(data);
    }

    getBoards();
  }, [match]);

  useEffect(() => {
    if (!showModalBoard) {
      setTimeout(() => {
        setBoardToEdit({
          company_id: match.params.id,
        });
      }, 500);
    }
  }, [showModalBoard, match]);

  const handleShowModalBoard = () => setShowModalBoard(true);

  const handleToEditBoard = board => {
    setBoardToEdit({ ...board, company_id: match.params.id });
    setShowModalBoard(true);
  };

  const updateBoards = newBoard => {
    const existsBoard = boards.find(item => item.id === newBoard.id);
    let updatedBoards = boards;
    if (existsBoard) {
      toast.success('Placa atualizada com sucesso');
      updatedBoards = updatedBoards.map(item => {
        if (item.id !== newBoard.id) return item;

        return {
          ...item,
          ...newBoard,
        };
      });
    } else {
      toast.success('Placa adicionada com sucesso');
      updatedBoards.push(newBoard);
    }

    setBoards(updatedBoards);
    setShowModalBoard(false);
  };

  return (
    <Container>
      <Navbar />

      {loadingBoards && <Loading label="Carregando placas..." />}

      {!loadingBoards && company && (
        <div className="container">
          <div className="row">
            <header className="col-12">
              <div>
                <p>Placas da empresa</p>
                <h3 className="CompanyName">{company.name}</h3>
              </div>
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleShowModalBoard}
              >
                Adicionar
              </button>
            </header>
          </div>
          <div className="row">
            <div className="col-12 col-md-8 offset-md-2">
              {boards.length === 0 && (
                <div className="text-center text-light">
                  Nenhuma placa cadastrada
                </div>
              )}

              <BoardsList>
                {boards.map(board => (
                  <li key={board.id}>
                    <button
                      type="button"
                      className="Description"
                      onClick={() => handleToEditBoard(board)}
                    >
                      <div className="Header">
                        <div className="Id">#{board.id}</div>
                        <strong className="Name">{board.description}</strong>
                        <LabelStatus active={board.active} />
                      </div>
                      <div className="Details">
                        <ul>
                          <li>
                            <small>Série</small>
                            <strong>{board.serie}</strong>
                          </li>
                          <li>
                            <small>Endereço</small>
                            <strong>
                              {board.dns_address}:{board.port}
                            </strong>
                          </li>
                          <li>
                            <small>Acionador</small>
                            <strong>{board.remote ? 'Sim' : 'Não'}</strong>
                          </li>
                        </ul>
                      </div>
                    </button>
                  </li>
                ))}
              </BoardsList>
            </div>
          </div>
        </div>
      )}
      <ModalAddBoard
        show={showModalBoard}
        board={boardToEdit}
        onHandleClose={() => setShowModalBoard(false)}
        onSaveBoard={updateBoards}
      />
    </Container>
  );
}
