import storage from './storage';
import { LEVEL_COMPANY } from '../constants';

const keyStorage = 'MONITORAMENTO_USER';
const auth = {
  getUser: () => storage.get(keyStorage),
  setUser: ({ token, user }) => {
    storage.set(keyStorage, { token, ...user });
  },
  removeUser: () => storage.remove(keyStorage),
  isAuthenticated: () => !!auth.getUser(),
  getLevel: () =>
    auth.getUser()
      ? parseInt(auth.getUser().access_level.id, 10)
      : LEVEL_COMPANY,
};

export default auth;
