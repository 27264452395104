import styled from 'styled-components';

const primaryColor = '#374667';

export const ContainerDashboard = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  background: ${primaryColor};
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
`;

export const FooterDashboard = styled.footer`
  border-top: 1px solid #fff;
  width: 100%;
  padding: 20px;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  flex-direction: column;
  @media (min-width: 768px) {
    justify-content: space-between;
    flex-direction: row;
  }
  div {
    display: flex;
    align-items: center;
    color: #fff;
    &.Company {
      font-size: 18px;
      line-height: 1;
      text-align: center;
      flex-direction: column;
      margin-bottom: 20px;
      @media (min-width: 768px) {
        margin-bottom: 0;
        text-align: center;
        flex-direction: row;
      }
      .FooterLogoWrapper {
        position: relative;
        button {
          background: transparent;
          border: 0;
        }
        img {
          height: 40px;
          margin-right: 10px;
        }
      }
    }
    &.Updates {
      text-align: right;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 20px;
      line-height: 1;
      text-align: center;
      @media (min-width: 768px) {
        align-items: flex-end;
      }
      small {
        font-size: 0.5em;
      }
    }
  }
`;

export const FooterMenuList = styled.ul`
  display: ${props => (props.visible ? 'flex' : 'none')};
  flex-direction: column;
  position: absolute;
  background: #fff;
  padding: 5px;
  bottom: 100%;
  right: 0;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  border-radius: 2px;

  li {
    display: flex;
    padding: 10px;

    a,
    button {
      font-size: 14px;
      color: #333;
      text-decoration: none;
      &:hover {
        color: #167ae9;
      }
    }
  }
`;

export const ContainerBoard = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px auto;
  justify-content: space-between;
  width: 100%;
  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

export const Contact = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
  }

  a,
  span {
    display: flex;
    margin: 5px auto;
    @media (min-width: 768px) {
      margin-right: 10px;
    }
    svg {
      margin-right: 5px;
    }
  }
`;
