import styled from 'styled-components';
import { lighten, darken } from 'polished';

const primaryColor = '#374667';

export const CompaniesContainer = styled.div`
  height: 100%;
  width: 100%;
  background: ${primaryColor};
  min-height: 100vh;
  header {
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    h2 {
      line-height: 1;
    }
  }
  .card {
    margin-top: 20px;
  }
`;

export const CompaniesList = styled.ul`
  list-style-type: none;
  margin: 20px 0 0;

  & > li {
    & + li {
      margin-top: 5px;
    }
    button {
      width: 100%;
      display: flex;
      flex-direction: column;
      background: ${lighten(0.05, primaryColor)};
      justify-content: space-between;
      border-radius: 3px;
      padding: 10px;
      border: 0;
      transition: all 0.3s ease;
      position: relative;

      @media (min-width: 768px) {
        height: 50px;
        align-items: center;
        flex-direction: row;
      }
      &:hover {
        box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);
        .Description {
          color: #fff;
        }
      }
      .Description {
        display: flex;
        background: transparent;
        border: 0;
        color: ${darken(0.2, '#fff')};
        align-items: center;

        .Id {
          @media (min-width: 768px) {
            padding: 0 20px;
          }
        }
        .Name {
          padding: 0 20px;
          text-decoration: underline;
        }
      }
      .Actions {
        align-items: center;
        justify-content: flex-end;
        margin-top: 10px;

        @media (min-width: 768px) {
          display: flex;
          margin-top: 0;
        }

        a,
        button {
          margin-left: 10px;
        }
      }
    }
  }
`;
