import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import pt from 'date-fns/locale/pt-BR';

import ContentDashboard from '../Dashboard/ContentDashboard';
import steps from './steps';

const INITIAL_BOARDS = [
  {
    id: 2,
    description: 'Placa 01 - Reservatório Leste',
    remote: true,
    company: {
      id: 1,
      name: 'Assunção Bombas',
      logo: {
        url: '/files/2d378bbb45e753ad5df97320883e966e.png',
        name: 'logo-assuncao-branco.png',
        path: '2d378bbb45e753ad5df97320883e966e.png',
      },
    },
    movement: [
      {
        id: 41,
        level_1: true,
        level_2: true,
        level_3: true,
        level_4: true,
        pump_on: false,
        comm_success: true,
        created_at: '2019-09-17 17:57:51',
      },
    ],
  },
  {
    id: 3,
    description: 'Placa 02 - Reservatório Oeste',
    remote: true,
    company: {
      id: 1,
      name: 'Assunção Bombas',
      logo: {
        url: '/files/2d378bbb45e753ad5df97320883e966e.png',
        name: 'logo-assuncao-branco.png',
        path: '2d378bbb45e753ad5df97320883e966e.png',
      },
    },
    movement: [
      {
        id: 41,
        level_1: true,
        level_2: true,
        level_3: true,
        level_4: true,
        pump_on: false,
        comm_success: true,
        created_at: '2019-09-17 17:57:51',
      },
    ],
  },
];

export default function DashboardMock() {
  const [boards, setBoards] = useState(INITIAL_BOARDS);
  const [updatedTime, setUpdatedTime] = useState(
    format(new Date(), 'dd/MM/yy HH:mm:ss', {
      locale: pt,
    })
  );
  const logoCompany = '/files/2d378bbb45e753ad5df97320883e966e.png';
  const [actualStep, setActualStep] = useState(0);

  const updateBoards = () => {
    const { movement, id: board } = steps[actualStep];
    const newBoards = boards.map(item => {
      if (item.id !== board) return item;

      return {
        ...item,
        movement,
      };
    });

    const newTime = format(new Date(), 'dd/MM/yy HH:mm:ss', {
      locale: pt,
    });

    setUpdatedTime(newTime);
    setBoards(newBoards);

    let newStep = actualStep === steps.length - 1 ? -1 : actualStep;
    newStep += 1;
    setActualStep(newStep);
  };

  useEffect(() => {
    setTimeout(() => {
      updateBoards();
    }, 2000);
  });

  return (
    <ContentDashboard
      logoCompany={logoCompany}
      loadingBoards={false}
      boards={boards}
      updatedTime={updatedTime}
    />
  );
}
