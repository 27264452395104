import { createGlobalStyle, keyframes } from 'styled-components';

// Create the keyframes
const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export default createGlobalStyle`
  *{
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box
  }
  html, body, #root{
    height: auto;
    min-height: 100vh;
  }
  button, input{
    outline: 0 !important;
  }

  body{
    background: #374667;
    -webkit-font-smoothing: antialiased !important;
  }
  body, input, button{
    color: #666;
    font-size: 14px;
    font-family: Arial, Helvetica, sans-serif;
  }
  button{
    cursor: pointer;
  }
  .spin{
    animation: ${rotate} 2s linear infinite;
  }
`;
