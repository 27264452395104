import React, { useState, useEffect } from 'react';
import { API_URL } from '../../constants';
import api from '../../services/api';

import { Container, Image } from './styles';

function LogoCompany({ logo, fallback }) {
  const [logoUrl, setLogoUrl] = useState(null);

  async function validateLogoUrl() {
    if (!logo && !logo.url) return;
    try {
      await api.get(logo.url);
      const urlToValidate = API_URL + logo.url;
      setLogoUrl(urlToValidate);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    validateLogoUrl();
  }, []); // eslint-disable-line

  useEffect(() => {
    validateLogoUrl();
  }, [logo]); // eslint-disable-line

  if (logoUrl) {
    return <Image src={logoUrl} />;
  }

  return <Container>{fallback}</Container>;
}

export default LogoCompany;
