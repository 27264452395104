import React, { useState, useEffect } from 'react';

import { toast } from 'react-toastify';
import api from '../../services/api';
import Navbar from '../../components/Navbar';
import { UsersContainer, UsersList, User } from './styles';
import ModalAddUser from '../../components/ModalAddUser';
import Loading from '../../components/Loading';
import LabelStatus from '../../components/LabelStatus';

export default function Users() {
  const [showModalUser, setShowModalUser] = useState(false);
  const [userToEdit, setUserToEdit] = useState(null);
  const [users, setUsers] = useState([]);
  const [loadingUsers, setLoadingUsers] = useState(false);

  useEffect(() => {
    async function getCompanies() {
      setLoadingUsers(true);
      const { data } = await api.get('/users');
      setLoadingUsers(false);
      setUsers(data);
    }

    getCompanies();
  }, []);

  useEffect(() => {
    if (!showModalUser) {
      setTimeout(() => {
        setUserToEdit(null);
      }, 500);
    }
  }, [showModalUser]);

  const handleShowModalUser = () => setShowModalUser(true);

  const handleToEditUser = user => {
    setUserToEdit(user);
    setShowModalUser(true);
  };

  const updateUsers = newUser => {
    const existsUser = users.find(item => item.id === newUser.id);
    let updatedUsers = users;
    if (existsUser) {
      toast.success('Usuário atualizado com sucesso');
      updatedUsers = updatedUsers.map(item => {
        if (item.id !== newUser.id) return item;

        return {
          ...item,
          ...newUser,
        };
      });
    } else {
      toast.success('Usuário adicionado com sucesso');
      updatedUsers.push(newUser);
    }

    setUsers(updatedUsers);
    setShowModalUser(false);
  };

  return (
    <UsersContainer>
      <Navbar />

      <div className="container">
        <div className="row">
          <header className="col-12 d-flex justify-space-between">
            <h2 className="mr-auto">Usuários</h2>
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleShowModalUser}
            >
              Adicionar
            </button>
          </header>
        </div>
        <div className="row">
          <div className="col-12 col-md-8 offset-md-2">
            {loadingUsers && <Loading label="Carregando usuários..." />}

            <UsersList>
              {users.map(user => (
                <User key={user.id} active={user.active}>
                  <button onClick={() => handleToEditUser(user)} type="button">
                    <div className="Description">
                      <div className="Id">{user.id}</div>
                      <strong className="Name">
                        {user.name}
                        <LabelStatus active={user.active} />
                      </strong>
                    </div>
                  </button>
                </User>
              ))}
            </UsersList>
          </div>
        </div>
      </div>
      <ModalAddUser
        show={showModalUser}
        user={userToEdit}
        onHandleClose={() => setShowModalUser(false)}
        onSaveUser={updateUsers}
      />
    </UsersContainer>
  );
}
