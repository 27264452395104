import styled, { keyframes } from 'styled-components';
import { lighten, rgba } from 'polished';

const cilindroHeight = 150;
const cilindroWidth = 200;

const primaryColor = '#374667';

export const WaterBox = styled.div`
  flex: 1;
  margin: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;

  .Label {
    text-align: center;
    color: #fff;
  }

  .Cilindro .c:first-child.active {
    background: #76c4ff !important;
    div {
      color: #fff;
    }
    &:after {
      background: transparent;
    }
  }

  .Cilindro .c:nth-child(2).active {
    background: #41a7ff !important;
    div {
      color: #fff;
    }
    &:after {
      background: transparent;
    }
  }

  .Cilindro .c:nth-child(3).active {
    background: #047ae9 !important;
    div {
      color: #fff;
    }
    &:after {
      background: transparent;
    }
  }

  .Cilindro .c:nth-child(4).active {
    background: #0064cc !important;
    div {
      color: #fff;
    }
    &:after {
      background: transparent;
    }
  }

  .Cilindro {
    list-style-type: none;
    z-index: 10;
    position: relative;
    margin-top: 20px;
    display: flex;
    flex-direction: column-reverse;
    width: ${cilindroWidth}px;

    .c {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 60px;

      color: #999;

      position: relative;
      margin: 0 auto;
      width: ${cilindroWidth}px;
      height: ${cilindroHeight}px;
      border-radius: 50px/25px;
      background-color: ${lighten(0.2, primaryColor)};

      &:nth-child(odd) {
        background-color: ${lighten(0.1, primaryColor)};
      }

      div {
        position: absolute;
        right: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
        color: #76c4ff;
        height: 100%;
        line-height: 1;
        .description {
          font-weight: 700;
          font-size: 1.3em;
        }
      }

      & + .c {
        margin-bottom: -${cilindroHeight / 2.5}px;
      }

      &:last-child {
        &:before {
          background-color: rgba(255, 255, 255, 0.2);
        }
      }
      &:first-child {
        &:after {
          background-color: transparent;
        }
      }

      &:before {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 50px;
        border-radius: 50px/25px;
        content: '';
      }

      &:after {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 50px;
        border-radius: 50px/25px;
        content: '';
      }
    }
  }
`;

export const Remote = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  text-align: right;
  margin-top: auto;
  margin-bottom: 80px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
  color: ${({ ligado }) => (ligado ? '#00FF89' : '#5d92c3')};

  svg {
    fill: ${({ ligado }) => (ligado ? '#00FF89' : '#5d92c3')};
  }
`;

const bounce = keyframes`
  from,
  20%,
  53%,
  80%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(0, 0, 0);
  }

  40%,
  43% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -5px, 0);
  }

  70% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -3px, 0);
  }

  90% {
    transform: translate3d(0, -1px, 0);
  }
`;

export const Alert = styled.section`
  display: flex;
  border-radius: 30px;
  position: absolute;
  z-index: 12;
  padding: 10px 25px;
  line-height: 1;
  opacity: 1;
  align-items: center;
  transition: all 0.3s ease;
  animation: ${bounce} 2s linear infinite;
  transform-origin: center bottom;

  background: ${props => {
    if (props.type === 'warning') return '#EAAD06';
    if (props.type === 'danger') return '#EA0606';
    if (props.type === 'muted') return '#6c757d';
    return '#666';
  }};

  box-shadow: ${props => {
    const shadow = '0 2px 10px';
    if (props.type === 'warning') return `${shadow} ${rgba('#EAAD06', 20)}`;
    if (props.type === 'danger') return `${shadow} ${rgba('#EA0606', 20)}`;
    if (props.type === 'muted') return `${shadow} ${rgba('#6c757d', 20)}`;
    return `${shadow} ${rgba('#666', 20)}`;
  }};

  svg {
    color: #fff;
    margin-right: 5px;
    font-size: 30px;
  }

  span {
    display: block;
    small,
    strong {
      color: #fff;
      display: block;
    }
  }
`;
