import styled from 'styled-components';
import { darken } from 'polished';
import { Navbar } from 'react-bootstrap';

const primaryColor = '#374667';

export const StyledNav = styled(Navbar)`
  background: ${darken(0.05, primaryColor)};
  width: 100%;
  margin-bottom: 20px;
`;
