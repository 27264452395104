import styled from 'styled-components';

export const LoadingStyle = styled.div`
  text-align: center;
  color: #fff;
  margin: auto;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    margin-right: 10px;
  }
`;
