import React from 'react';
import PropTypes from 'prop-types';

import { FaWhatsapp } from 'react-icons/fa';
import {
  ContainerDashboard,
  ContainerBoard,
  FooterDashboard,
  Contact,
} from '../styles';
import logoMonitoramento from '../../../images/logo-assuncao-branco.png';
import ItemBoard from '../ItemBoard';
import Loading from '../../../components/Loading';
import Navbar from '../../../components/Navbar';
import LogoCompany from '../../../components/LogoCompany';

export default function ContentDashboard({
  company,
  loadingBoards,
  boards,
  updatedTime,
  onHandleSyncronize,
}) {
  return (
    <ContainerDashboard>
      <Navbar />

      {loadingBoards && (
        <Loading style={{ marginTop: '50px' }} label="Carregando placas..." />
      )}

      {!loadingBoards && (
        <>
          <h3 className="text-center text-white m-auto">
            Sistema de Monitoramento dos Reservatórios de Água e Controle das
            Bombas
          </h3>
          {company && (
            <LogoCompany logo={company.logo} fallback={company.name} />
          )}

          <ContainerBoard>
            {boards
              .filter(item => item.movement && item.movement.length > 0)
              .map(item => (
                <ItemBoard {...item} key={item.id} />
              ))}
          </ContainerBoard>
          {onHandleSyncronize && (
            <div className="text-center m-auto">
              <button
                type="button"
                className="btn btn-outline-light"
                onClick={onHandleSyncronize}
              >
                Sincronizar
              </button>
            </div>
          )}
        </>
      )}

      {updatedTime && (
        <FooterDashboard>
          <div className="Company">
            <div className="FooterLogoWrapper">
              <button type="button">
                <img
                  src={logoMonitoramento}
                  alt="Sistema de Monitoramento dos Reservatórios de Água e Controle das Bombas"
                />
              </button>
            </div>
            <Contact>
              <a
                href="https://www.assuncaobombas.com.br/"
                target="_blank"
                className="text-white"
                rel="noopener noreferrer"
                title="Assunção Bomba"
              >
                www.assuncaobombas.com.br
              </a>
              <span>
                <FaWhatsapp /> (17) 98828-9884
              </span>
            </Contact>
          </div>
          <div className="Updates">
            <small>Atualizado em</small>
            <strong>{updatedTime}</strong>
          </div>
        </FooterDashboard>
      )}
    </ContainerDashboard>
  );
}

ContentDashboard.propTypes = {
  company: PropTypes.shape({
    name: PropTypes.string,
    logo: PropTypes.shape({
      url: PropTypes.string,
    }),
  }),
  loadingBoards: PropTypes.bool.isRequired,
  boards: PropTypes.arrayOf(
    PropTypes.shape({
      movement: PropTypes.arrayOf(PropTypes.object),
    })
  ).isRequired,
  updatedTime: PropTypes.string,
  onHandleSyncronize: PropTypes.func,
};

ContentDashboard.defaultProps = {
  company: null,
  updatedTime: null,
  onHandleSyncronize: null,
};
