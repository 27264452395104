import React, { useState, useEffect } from 'react';
import { IoIosWarning } from 'react-icons/io';
import { FiWifiOff } from 'react-icons/fi';

import { WaterBox, Remote, Alert } from './styles';
import IconeBomba from './IconeBomba';
import api from '../../../services/api';

export default function ItemBoard({
  description,
  id,
  remote,
  movement,
  description_level_1,
  description_level_2,
  description_level_3,
  description_level_4,
}) {
  const [activatingRemote, setActivatingRemote] = useState(false);

  const latestMovement = movement[0];
  const {
    level_1,
    level_2,
    level_3,
    level_4,
    comm_success,
    pump_on,
    // created_at,
  } = latestMovement;

  useEffect(() => {
    setActivatingRemote(false);
  }, [movement]);

  const handleRemote = async () => {
    setActivatingRemote(true);
    await api.post('boards/activate/pump', {
      board_id: id,
      pump_on: !pump_on,
    });
  };
  console.log(comm_success);
  return (
    <WaterBox>
      <section>
        <ul className="Cilindro">
          <li className={`c ${level_1 && 'active'}`}>
            {!level_1 && comm_success && (
              <Alert type="warning">
                <IoIosWarning />
                <span>
                  <small>Alerta de</small>
                  <strong>Esgotamento</strong>
                </span>
              </Alert>
            )}
            {!comm_success && (
              <Alert type="muted">
                <FiWifiOff />
                <span>
                  <small>Alerta</small>
                  <strong>Desconectado</strong>
                </span>
              </Alert>
            )}
            <div>
              <span>1/4</span>
              {description_level_1 && (
                <span className="description">{description_level_1}</span>
              )}
            </div>
          </li>
          <li className={`c ${level_2 && 'active'}`}>
            <div>
              <span>2/4</span>
              {description_level_2 && (
                <span className="description">{description_level_2}</span>
              )}
            </div>
          </li>
          <li className={`c ${level_3 && 'active'}`}>
            <div>
              <span>3/4</span>
              {description_level_3 && (
                <span className="description">{description_level_3}</span>
              )}
            </div>
          </li>
          <li className={`c ${level_4 && 'active'}`}>
            {level_4 && (
              <Alert type="danger">
                <IoIosWarning />
                <span>
                  <small>Alerta de</small>
                  <strong>Transbordo</strong>
                </span>
              </Alert>
            )}
            <div>
              <span>4/4</span>
              {description_level_4 && (
                <span className="description">{description_level_4}</span>
              )}
            </div>
          </li>
        </ul>
        <footer className="Label">
          <strong>{description}</strong>
          <p>#{id}</p>
        </footer>
      </section>
      {remote && (
        <Remote ligado={pump_on} onClick={handleRemote}>
          <IconeBomba />
          {activatingRemote ? (
            <span>{pump_on ? 'Desligando..' : 'Ligando..'}</span>
          ) : (
            <span>{pump_on ? 'Ligado' : 'Desligado'}</span>
          )}
        </Remote>
      )}
    </WaterBox>
  );
}
