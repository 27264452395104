export default [
  {
    id: 2,
    movement: [
      {
        id: 41,
        level_1: true,
        level_2: true,
        level_3: true,
        level_4: false,
        pump_on: false,
        comm_success: true,
        created_at: '2019-09-17 17:57:51',
      },
    ],
  },
  {
    id: 2,
    movement: [
      {
        id: 41,
        level_1: true,
        level_2: true,
        level_3: false,
        level_4: false,
        pump_on: false,
        comm_success: true,
        created_at: '2019-09-17 17:57:51',
      },
    ],
  },
  {
    id: 2,
    movement: [
      {
        id: 41,
        level_1: true,
        level_2: false,
        level_3: false,
        level_4: false,
        pump_on: false,
        comm_success: true,
        created_at: '2019-09-17 17:57:51',
      },
    ],
  },
  {
    id: 2,
    movement: [
      {
        id: 41,
        level_1: false,
        level_2: false,
        level_3: false,
        level_4: false,
        pump_on: false,
        comm_success: true,
        created_at: '2019-09-17 17:57:51',
      },
    ],
  },
  {
    id: 2,
    movement: [
      {
        id: 41,
        level_1: false,
        level_2: false,
        level_3: false,
        level_4: false,
        pump_on: true,
        comm_success: true,
        created_at: '2019-09-17 17:57:51',
      },
    ],
  },
  {
    id: 2,
    movement: [
      {
        id: 41,
        level_1: true,
        level_2: false,
        level_3: false,
        level_4: false,
        pump_on: true,
        comm_success: true,
        created_at: '2019-09-17 17:57:51',
      },
    ],
  },
  {
    id: 2,
    movement: [
      {
        id: 41,
        level_1: true,
        level_2: true,
        level_3: false,
        level_4: false,
        pump_on: true,
        comm_success: true,
        created_at: '2019-09-17 17:57:51',
      },
    ],
  },
  {
    id: 2,
    movement: [
      {
        id: 41,
        level_1: true,
        level_2: true,
        level_3: true,
        level_4: false,
        pump_on: true,
        comm_success: true,
        created_at: '2019-09-17 17:57:51',
      },
    ],
  },
  {
    id: 2,
    movement: [
      {
        id: 41,
        level_1: true,
        level_2: true,
        level_3: true,
        level_4: true,
        pump_on: true,
        comm_success: true,
        created_at: '2019-09-17 17:57:51',
      },
    ],
  },
  {
    id: 2,
    movement: [
      {
        id: 41,
        level_1: true,
        level_2: true,
        level_3: true,
        level_4: true,
        pump_on: false,
        comm_success: true,
        created_at: '2019-09-17 17:57:51',
      },
    ],
  },
];
