import React, { Component } from 'react';
import { format } from 'date-fns';
import pt from 'date-fns/locale/pt-BR';
import io from 'socket.io-client';

import api from '../../services/api';
import { LEVEL_COMPANY, SOCKET_PORT } from '../../constants';
import auth from '../../services/auth';
import ContentDashboard from './ContentDashboard';

const socket = io(SOCKET_PORT);

export default class App extends Component {
  state = {
    boards: [],
    updatedTime: null,
    company: null,
    loadingBoards: false,
  };

  componentWillUnmount() {
    socket.disconnect();
  }

  componentDidMount = () => {
    const { match, history } = this.props;
    const company_id = match.params.id || auth.getUser().company.id;

    const getBoards = async () => {
      this.setLoadingBoards(true);
      let responseCompany = null;
      if (auth.getLevel() === LEVEL_COMPANY) {
        responseCompany = auth.getUser().company;
      } else if (match.params && match.params.id) {
        const { data } = await api.get(`/companies/${match.params.id}`);
        responseCompany = data;
      } else {
        history.push('/empresas');
        return;
      }

      const { data } = await api.get(`/movements/${responseCompany.id}`);
      this.setBoards(data);

      const newTime = format(new Date(), 'dd/MM/yy HH:mm:ss', {
        locale: pt,
      });

      this.setUpdatedTime(newTime);
      this.setCompany(responseCompany);
      this.setLoadingBoards(false);
    };
    getBoards();

    socket.on(`newMovement_${company_id}`, ({ movement, board }) => {
      const { boards } = this.state;
      if (process.env.NODE_ENV !== 'production') {
        console.log(`newMovement_${company_id}`, movement, board);
      }
      this.setLoadingBoards(false);
      const newBoards = boards.map(item => {
        if (item.id !== board) {
          return item;
        }

        return {
          ...item,
          movement: [movement],
        };
      });

      const newTime = format(new Date(), 'dd/MM/yy HH:mm:ss', {
        locale: pt,
      });

      this.setUpdatedTime(newTime);

      this.setBoards(newBoards);
    });
    socket.on(`errorMovement_${company_id}`, ({ error, board }) => {
      this.setLoadingBoards(false);
      const { boards } = this.state;
      const newBoards = boards.map(item => {
        if (item.id !== board) return item;

        return {
          ...item,
          error,
        };
      });

      this.setBoards(newBoards);
    });
  };

  setBoards = boards => {
    this.setState({ boards });
  };

  setUpdatedTime = updatedTime => {
    this.setState({ updatedTime });
  };

  setCompany = company => {
    this.setState({ company });
  };

  setLoadingBoards = loadingBoards => {
    this.setState({ loadingBoards });
  };

  handleSyncronize = () => {
    this.setLoadingBoards(true);
    api.get('/syncronize');
  };

  render() {
    const { company, loadingBoards, boards, updatedTime } = this.state;

    return (
      <>
        <ContentDashboard
          company={company}
          loadingBoards={loadingBoards}
          boards={boards}
          updatedTime={updatedTime}
          onHandleSyncronize={this.handleSyncronize}
        />
      </>
    );
  }
}
