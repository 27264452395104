import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { FaCircleNotch, FaWhatsapp } from 'react-icons/fa';
import qs from 'qs';
import io from 'socket.io-client';

import { Alert } from 'react-bootstrap';
import api from '../../services/api';
import { LoginContainer, ErrorMessage } from './styles';
import auth from '../../services/auth';
import { SubmitButton } from '../../styles/button';
import { LEVEL_COMPANY, SOCKET_PORT } from '../../constants';
import logoMonitoramento from '../../images/logo-assuncao-branco.png';

export default function Login({ history, location }) {
  const [errorLogin, setErrorLogin] = useState(null);

  useEffect(() => {
    const socket = io(`${SOCKET_PORT}`);

    socket.on('newMovement_2', newMovement => {
      console.log('newMovement_2', newMovement);
    });
  }, []);

  const doLogin = async ({ username, password }, actions) => {
    history.push({
      search: '',
    });
    setErrorLogin(null);
    try {
      const { data } = await api.post('/sessions', { username, password });
      actions.setSubmitting(false);
      auth.setUser(data);

      if (auth.getLevel() === LEVEL_COMPANY) {
        history.push('/');
        return;
      }

      // if (location.state && location.state.from) {
      //   history.push(location.state.from);
      // } else {
      history.push('/empresas');
      // }
    } catch (e) {
      actions.setSubmitting(false);
      console.error(e);
      setErrorLogin('Usuário ou senha incorretos');
    }
  };

  const LoginSchema = Yup.object().shape({
    username: Yup.string().required('Informe o usuário'),
    password: Yup.string().required('Informe a password'),
  });

  const query = qs.parse(location.search, { ignoreQueryPrefix: true });
  const isLogout = query.logout;

  return (
    <LoginContainer>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-12 col-md-8 offset-md-2">
            <h3 className="text-light text-center">
              Sistema de Monitoramento dos Reservatórios de Água e Controle das
              Bombas
            </h3>
            <div className="col-12 col-md-6 offset-md-3">
              <div className="card shadow border-0">
                <div className="card-body">
                  {isLogout && <Alert variant="info">Você desconectou</Alert>}
                  <Formik
                    initialValues={{ username: '', password: '' }}
                    onSubmit={doLogin}
                    validationSchema={LoginSchema}
                  >
                    {({
                      handleSubmit,
                      handleChange,
                      handleBlur,
                      values,
                      errors,
                      isSubmitting,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="form-group">
                          <label htmlFor="username">Usuário</label>
                          <input
                            type="text"
                            id="username"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.username}
                            name="username"
                            className={`form-control ${errors.username &&
                              'is-invalid'}`}
                            placeholder="Seu usuário"
                            disabled={isSubmitting}
                          />
                          {errors.username && (
                            <div className="invalid-feedback">
                              {errors.username}
                            </div>
                          )}
                        </div>
                        <div className="form-group">
                          <label htmlFor="password">Senha</label>
                          <input
                            type="password"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.password}
                            name="password"
                            className={`form-control ${errors.password &&
                              'is-invalid'}`}
                            placeholder="Sua password"
                            disabled={isSubmitting}
                          />
                          {errors.password && (
                            <div className="invalid-feedback">
                              {errors.password}
                            </div>
                          )}
                        </div>

                        {errorLogin && (
                          <ErrorMessage>{errorLogin}</ErrorMessage>
                        )}

                        <SubmitButton isSubmitting={isSubmitting}>
                          {isSubmitting ? (
                            <>
                              <FaCircleNotch size={15} className="spin" />{' '}
                              Entrando...
                            </>
                          ) : (
                            'Entrar'
                          )}
                        </SubmitButton>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
            <footer className="text-center mt-4">
              <img
                src={logoMonitoramento}
                width={150}
                alt="Sistema de Monitoramento dos Reservatórios de Água e Controle das
                  Bombas"
              />
              <div className="text-white">
                <a
                  href="https://www.assuncaobombas.com.br/"
                  target="_blank"
                  className="text-white"
                  rel="noopener noreferrer"
                  title="Assunção Bomba"
                >
                  www.assuncaobombas.com.br
                </a>
                <div>
                  <FaWhatsapp />
                  &nbsp; (17) 98828-9884
                </div>
              </div>
            </footer>
          </div>
        </div>
      </div>
    </LoginContainer>
  );
}
