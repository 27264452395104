import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { FaCircleNotch } from 'react-icons/fa';
import MaskedInput from 'react-text-mask';

import { SubmitButton } from '../../styles/button';
import api from '../../services/api';
import { maskCellPhone } from '../../utils/mask';

import { LEVEL_ADMIN } from '../../constants';

export default function ModalAddUser({
  show,
  onHandleClose,
  user = null,
  onSaveUser,
}) {
  const [errorSavingUser, setErrorSavingUser] = useState(null);
  const [companies, setCompanies] = useState([]);

  const UserSchema = Yup.object().shape({
    name: Yup.string().required('Informe o nome'),
    username: Yup.string().required('Informe o usuário'),
    password: Yup.string()
      .min(6, 'A senha precisa conter no mínimo 6 dígitos')
      .when('id', (id, schema) => {
        if (id) {
          return schema;
        }
        return schema.required('Informe uma senha');
      }),
    email: Yup.string()
      .email('Informe um email válido')
      .required('Informe um email'),
    cell_phone: Yup.string().required('Informe um telefone'),
    access_level_id: Yup.string(),
    company_id: Yup.string()
      .when('access_level_id', (access_level_id, schema) => {
        if (Number(access_level_id) === LEVEL_ADMIN) {
          return schema;
        }

        return schema.required('Selecione uma empresa');
      })
      .nullable(),
    active: Yup.string(),
  });

  useEffect(() => {
    const fetchCompanies = async () => {
      const { data } = await api.get('/companies');
      setCompanies(data);
    };

    fetchCompanies();
  }, []);

  const saveUser = async (request, actions) => {
    setErrorSavingUser(null);
    try {
      let response = {};
      if (request.id) {
        response = await api.put(`/users/${request.id}`, request);
      } else {
        response = await api.post('/users', request);
      }
      const { data } = response;
      onSaveUser(data);
      actions.setSubmitting(false);
    } catch ({ response }) {
      console.error(response);
      const { error } = response.data;
      actions.setSubmitting(false);
      if (error === 'Username already exists.') {
        setErrorSavingUser('O usuário já está sendo utilizado');
      } else {
        setErrorSavingUser('Erro ao criar o usuário');
      }
    }
  };

  const initialValues = user || { name: '', access_level_id: 2, active: true };

  return (
    <>
      <Modal show={show} onHide={onHandleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>{user ? 'Editando' : 'Adicionando'} Usuário</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={initialValues}
            onSubmit={saveUser}
            validationSchema={UserSchema}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              setFieldValue,
              values,
              errors,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit} noValidate>
                {user && user.id && (
                  <div className="form-group form-check">
                    <input
                      type="checkbox"
                      id="active"
                      onChange={handleChange}
                      checked={values.active}
                      name="active"
                      className={`form-check-input ${errors.active &&
                        'is-invalid'}`}
                      disabled={isSubmitting}
                    />
                    <label className="form-check-label" htmlFor="active">
                      Usuário Ativo
                    </label>
                    {errors.active && (
                      <div className="invalid-feedback">{errors.active}</div>
                    )}
                  </div>
                )}
                <div className="form-group">
                  <label htmlFor="name">Nome do usuário*</label>
                  <input
                    type="text"
                    id="name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                    name="name"
                    className={`form-control ${errors.name && 'is-invalid'}`}
                    placeholder="Nome do usuário"
                    disabled={isSubmitting}
                  />
                  {errors.name && (
                    <div className="invalid-feedback">{errors.name}</div>
                  )}
                </div>
                <div className="form-group">
                  <label htmlFor="username">Usuário*</label>
                  <input
                    type="text"
                    id="username"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.username}
                    name="username"
                    className={`form-control ${errors.username &&
                      'is-invalid'}`}
                    placeholder="usuário"
                    disabled={isSubmitting}
                  />
                  {errors.username && (
                    <div className="invalid-feedback">{errors.username}</div>
                  )}
                </div>
                <div className="form-group">
                  <label htmlFor="email">Email*</label>
                  <input
                    type="text"
                    id="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    name="email"
                    className={`form-control ${errors.email && 'is-invalid'}`}
                    placeholder="usuario@email.com"
                    disabled={isSubmitting}
                  />
                  {errors.email && (
                    <div className="invalid-feedback">{errors.email}</div>
                  )}
                </div>
                <div className="form-group">
                  <label htmlFor="password">Senha*</label>
                  <input
                    type="password"
                    id="password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                    name="password"
                    className={`form-control ${errors.password &&
                      'is-invalid'}`}
                    placeholder={
                      values.id ? 'manter senha' : 'senha do usuário'
                    }
                    disabled={isSubmitting}
                  />
                  {errors.password && (
                    <div className="invalid-feedback">{errors.password}</div>
                  )}
                </div>
                <div className="form-group">
                  <label htmlFor="cell_phone">Telefone*</label>
                  <MaskedInput
                    mask={maskCellPhone}
                    type="tel"
                    id="cell_phone"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.cell_phone}
                    name="cell_phone"
                    className={`form-control ${errors.cell_phone &&
                      'is-invalid'}`}
                    placeholder="(00) 0000-0000"
                    disabled={isSubmitting}
                  />
                  {errors.cell_phone && (
                    <div className="invalid-feedback">{errors.cell_phone}</div>
                  )}
                </div>

                <div className="form-group">
                  <label htmlFor="access_level_id">Nível de acesso*</label>
                  <select
                    id="access_level_id"
                    onChange={e => {
                      handleChange(e);
                      setFieldValue('company_id', '');
                    }}
                    onBlur={handleBlur}
                    value={values.access_level_id}
                    name="access_level_id"
                    className={`form-control ${errors.access_level_id &&
                      'is-invalid'}`}
                    placeholder="Nome do usuário"
                    disabled={isSubmitting}
                  >
                    <option value="2">Empresa</option>
                    <option value="1">Administrador</option>
                  </select>
                  {errors.access_level_id && (
                    <div className="invalid-feedback">
                      {errors.access_level_id}
                    </div>
                  )}
                </div>

                {Number(values.access_level_id) !== LEVEL_ADMIN && (
                  <div className="form-group">
                    <label htmlFor="company_id">Empresa*</label>
                    {!companies && <p>Carregando empresas...</p>}
                    {companies && (
                      <select
                        id="company_id"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.company_id || ''}
                        name="company_id"
                        className={`form-control ${errors.company_id &&
                          'is-invalid'}`}
                        placeholder="Nome do usuário"
                        disabled={isSubmitting}
                      >
                        <option value="">Selecione uma empresa</option>
                        {companies.map(item => (
                          <option key={item.id} value={item.id}>
                            {item.name}
                          </option>
                        ))}
                      </select>
                    )}
                    {errors.company_id && (
                      <div className="invalid-feedback">
                        {errors.company_id}
                      </div>
                    )}
                  </div>
                )}

                {errorSavingUser && (
                  <div className="text-danger">{errorSavingUser}</div>
                )}

                <div className="d-flex justify-content-between">
                  <SubmitButton isSubmitting={isSubmitting}>
                    {isSubmitting ? (
                      <>
                        <FaCircleNotch size={15} className="spin" /> Salvando...
                      </>
                    ) : (
                      'Salvar'
                    )}
                  </SubmitButton>
                </div>
              </form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
}
