import styled from 'styled-components';

export const Container = styled.span`
  line-height: 1;
  font-size: 11px;
  padding: 3px;
  margin-left: 5px;
  border-radius: 2px;
  border: 1px solid;
  border-color: ${props => (props.active ? '#00FF89' : 'rgba(255,255,255,.5)')};
  color: ${props => (props.active ? '#00FF89' : 'rgba(255,255,255,.5)')};
`;
