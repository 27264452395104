import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import { SubmitButton } from '../../styles/button';
import api from '../../services/api';
import Loading from '../Loading';

export default function ModalAddBoard({
  show,
  onHandleClose,
  board = null,
  onSaveBoard,
}) {
  const [errorSavingBoard, setErrorSavingBoard] = useState(null);

  const BoardSchema = Yup.object().shape({
    description: Yup.string().required('Informe o nome da placa'),
    serie: Yup.string().required('Informe o número de série da placa'),
    ip_address: Yup.string().required('Informe um ip válido'),
    dns_address: Yup.string().required('Informe um DNS'),
    port: Yup.number()
      .min(1000, 'Informe uma porta válida')
      .max(99999, 'Informe uma porta válida')
      .required('Informe a porta da placa'),
    remote: Yup.bool(),
    active: Yup.bool(),
  });

  const saveBoard = async (request, actions) => {
    setErrorSavingBoard(null);
    try {
      let response = {};
      if (request.id) {
        response = await api.put(`/boards/${request.id}`, request);
      } else {
        response = await api.post('/boards', request);
      }
      const { data } = response;
      api.get('/syncronize');
      onSaveBoard(data);
      actions.setSubmitting(false);
    } catch (e) {
      console.error(e);
      actions.setSubmitting(false);
      setErrorSavingBoard('Erro ao cadastrar placa');
    }
  };

  const initialValues =
    board && board.id
      ? board
      : {
          ...board,
          description: '',
          dns_address: '',
          ip_address: '',
          port: '',
          serie: '',
          active: true,
          description_level_1: '',
          description_level_2: '',
          description_level_3: '',
          description_level_4: '',
        };

  return (
    <Modal show={show} onHide={onHandleClose} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>
          {board && board.id ? 'Editando' : 'Adicionando'} Placa
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={initialValues}
          onSubmit={saveBoard}
          validationSchema={BoardSchema}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            errors,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              {board && board.id && (
                <div className="form-group form-check">
                  <input
                    type="checkbox"
                    id="active"
                    onChange={handleChange}
                    checked={values.active}
                    name="active"
                    className={`form-check-input ${errors.active &&
                      'is-invalid'}`}
                    disabled={isSubmitting}
                  />
                  <label className="form-check-label" htmlFor="active">
                    Placa Ativa
                  </label>
                  {errors.active && (
                    <div className="invalid-feedback">{errors.active}</div>
                  )}
                </div>
              )}
              <div className="form-group">
                <label htmlFor="description">Nome da placa</label>
                <input
                  type="text"
                  id="description"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.description}
                  name="description"
                  className={`form-control ${errors.description &&
                    'is-invalid'}`}
                  placeholder="Nome da placa"
                  disabled={isSubmitting}
                />
                {errors.description && (
                  <div className="invalid-feedback">{errors.description}</div>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="serie">Número de série</label>
                <input
                  type="tel"
                  id="serie"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.serie}
                  name="serie"
                  className={`form-control ${errors.serie && 'is-invalid'}`}
                  placeholder="Ex.: 123456"
                  disabled={isSubmitting}
                />
                {errors.serie && (
                  <div className="invalid-feedback">{errors.serie}</div>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="dns_address">Endereço DNS*</label>
                <input
                  type="tel"
                  id="dns_address"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.dns_address}
                  name="dns_address"
                  className={`form-control ${errors.dns_address &&
                    'is-invalid'}`}
                  placeholder="Ex.: 123456"
                  disabled={isSubmitting}
                />
                {errors.dns_address && (
                  <div className="invalid-feedback">{errors.dns_address}</div>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="ip_address">IP*</label>
                <input
                  type="tel"
                  id="ip_address"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="ip_address"
                  value={values.ip_address}
                  className={`form-control ${errors.ip_address &&
                    'is-invalid'}`}
                  placeholder="000.000.000.000"
                  disabled={isSubmitting}
                />
                {errors.ip_address && (
                  <div className="invalid-feedback">{errors.ip_address}</div>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="port">Porta*</label>
                <input
                  type="tel"
                  id="port"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="port"
                  value={values.port}
                  className={`form-control ${errors.port && 'is-invalid'}`}
                  placeholder="Ex.: 5000"
                  disabled={isSubmitting}
                />
                {errors.port && (
                  <div className="invalid-feedback">{errors.port}</div>
                )}
              </div>
              <div className="form-group form-check">
                <input
                  type="checkbox"
                  id="remote"
                  onChange={handleChange}
                  checked={values.remote}
                  name="remote"
                  className={`form-check-input ${errors.remote &&
                    'is-invalid'}`}
                  disabled={isSubmitting}
                />
                <label className="form-check-label" htmlFor="remote">
                  Possui acionamento remoto
                </label>
                {errors.remote && (
                  <div className="invalid-feedback">{errors.remote}</div>
                )}
              </div>

              <hr />

              <div className="row">
                <div className="col-12 col-md-6">
                  <div className="form-group">
                    <label htmlFor="description_level_1">
                      Descrição do Sensor 1
                    </label>
                    <input
                      id="description_level_1"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="description_level_1"
                      value={values.description_level_1}
                      className={`form-control ${errors.description_level_1 &&
                        'is-invalid'}`}
                      placeholder="Ex.: 16.900L"
                      disabled={isSubmitting}
                    />
                    {errors.description_level_1 && (
                      <div className="invalid-feedback">
                        {errors.description_level_1}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group">
                    <label htmlFor="description_level_2">
                      Descrição do Sensor 2
                    </label>
                    <input
                      id="description_level_2"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="description_level_2"
                      value={values.description_level_2}
                      className={`form-control ${errors.description_level_2 &&
                        'is-invalid'}`}
                      placeholder="Ex.: 16.900L"
                      disabled={isSubmitting}
                    />
                    {errors.description_level_2 && (
                      <div className="invalid-feedback">
                        {errors.description_level_2}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group">
                    <label htmlFor="description_level_3">
                      Descrição do Sensor 3
                    </label>
                    <input
                      id="description_level_3"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="description_level_3"
                      value={values.description_level_3}
                      className={`form-control ${errors.description_level_3 &&
                        'is-invalid'}`}
                      placeholder="Ex.: 16.900L"
                      disabled={isSubmitting}
                    />
                    {errors.description_level_3 && (
                      <div className="invalid-feedback">
                        {errors.description_level_3}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group">
                    <label htmlFor="description_level_4">
                      Descrição do Sensor 4
                    </label>
                    <input
                      id="description_level_4"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="description_level_4"
                      value={values.description_level_4}
                      className={`form-control ${errors.description_level_4 &&
                        'is-invalid'}`}
                      placeholder="Ex.: 16.900L"
                      disabled={isSubmitting}
                    />
                    {errors.description_level_4 && (
                      <div className="invalid-feedback">
                        {errors.description_level_4}
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {errorSavingBoard && (
                <ErrorMessage>{errorSavingBoard}</ErrorMessage>
              )}

              <SubmitButton isSubmitting={isSubmitting}>
                {isSubmitting ? (
                  <>
                    <Loading label="Salvando..." />
                  </>
                ) : (
                  'Salvar'
                )}
              </SubmitButton>
            </form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
}
