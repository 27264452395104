import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

import { StyledNav } from './styles';
import logoMonitoramento from '../../images/logo-assuncao-branco.png';

import { LEVEL_ADMIN } from '../../constants';
import auth from '../../services/auth';

export default function CustomNavbar() {
  return (
    <StyledNav expand="lg" variant="dark">
      <Navbar.Brand href="/">
        <img
          src={logoMonitoramento}
          height={40}
          alt="Sistema de Monitoramento dos Reservatórios de Água e Controle das Bombas"
        />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        {auth.getLevel() === LEVEL_ADMIN && (
          <Nav className="mr-auto">
            <li className="nav-item">
              <NavLink className="nav-link" to="/empresas">
                Empresas
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/usuarios">
                Usuários
              </NavLink>
            </li>
          </Nav>
        )}
        <Nav className="ml-auto">
          <Nav.Link href="/logout">Sair</Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </StyledNav>
  );
}
