import styled from 'styled-components';
import { CompaniesContainer, CompaniesList } from '../Companies/styles';

export const Container = styled(CompaniesContainer)`
  .CompanyName {
    line-height: 1;
    margin-right: auto;
    margin-bottom: 0;
  }
  p {
    margin-bottom: 0;
  }
`;

export const BoardsList = styled(CompaniesList)`
  .Description {
    width: 100%;
    flex-direction: column;
    color: #fff;
    @media (min-width: 768px) {
      flex-direction: row;
      justify-content: space-between;
    }
    .Header {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      @media (min-width: 768px) {
        margin-bottom: 0;
      }
      .Id,
      .Name {
        padding: 0 10px;
      }
    }
    .Details {
      ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
        display: flex;
        @media (min-width: 768px) {
          text-align: right;
        }
        & > li {
          display: flex;
          flex-direction: column;
          line-height: 1;
          & + li {
            margin-left: 20px;
          }
        }
      }
    }
  }
`;
