export const MASK_CNPJ = [
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '/',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
];

export const MASK_CEL = [
  '(',
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];
export const MASK_CEL_9 = [
  '(',
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

export const maskCellPhone = phone => {
  if (!phone) {
    return MASK_CEL;
  }

  const numbers = phone.replace(/\s|-|_|\.|\(|\)/g, '');
  let numberLength = 0;
  if (numbers) {
    numberLength = numbers.length;
  }

  return numberLength > 10 ? MASK_CEL_9 : MASK_CEL;
};
